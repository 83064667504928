<template>
  <!-- 项目概况 or 学习概况 -->
  <div class="situation">
    <!--  -->
    <div class="notice-and-tools flex-between">
      <div class="notice-box box-wrapper">
        <div class="notice-title flex-align-between">
          <h4 class="left">通知公告</h4>
          <button class="more csp"
            @click="$router.push({path:'/newswork',query:{projectId:currentProject.id,breadNumber:4}})">更多
            &gt;</button>
        </div>
        <ul class="notice-list" v-if="NoticeList.length > 0">
          <li class="paragraph flex-align-between csp" v-for="(item,index) in NoticeList" :key="index"
            @click="$router.push({path:'/news/notice',query:{projectId:currentProject.id,id:item.id}})">
            <p class="date-name wes">{{item.title}}</p>
            <p class="date-days">{{dayjs(item.createTime).format('YYYY-MM-DD')}}</p>
          </li>
        </ul>
        <Empty :show="NoticeList && NoticeList.length === 0"></Empty>
      </div>
      <div class="tools-box box-wrapper">
        <h4 class="tools-title">常用工具</h4>
        <!-- 专家常用工具 -->
        <ul class="tolls-list" v-if="currentRole == 'expert'">
          <li class="tools-item flex-align-between csp" v-for="(item,index) in exportToolsList" :key="index"
            @click="expertToToolsPage(item)">
            <img :src="item.icon" alt="" class="tools-item-icon">
            <h6 class="tools-item-name">{{item.name}}</h6>
            <i class="el-icon-arrow-right"></i>
          </li>
        </ul>
        <!-- 其他身份常用工具 -->
        <ul class="tolls-list" v-else>
          <li class="tools-item flex-align-between csp" v-for="(item,index) in toolsList" :key="index"
            @click="toToolsPage(item)">
            <img :src="item.icon" alt="" class="tools-item-icon">
            <h6 class="tools-item-name">{{item.name}}</h6>
            <i class="el-icon-arrow-right"></i>
          </li>
        </ul>
      </div>
    </div>
    <div class="dealtwith-and-guide flex-between">
      <div class="dealtwith-box box-wrapper">
        <h4 class="dealtwith-title">待办事项</h4>
        <!-- 专家待办事项 -->
        <ul class="dealtwith-list" v-if="currentRole == 'expert'">
          <li class="dealtwith-item flex-align-between csp">
            <p class="date-name wes">您有
              <span class="date-num">{{expertDealtList[0].unCorrecting}}</span>
              个作业
              <span class="date-num">{{expertDealtList[0].unCorrectingAnswer}}</span>
              份作业待批改
            </p>
            <!-- <p class="date-days">{{dayjs(item.createTime).format('YYYY-MM-DD')}}</p> -->
          </li>
          <li class="dealtwith-item flex-align-between csp">
            <p class="date-name wes">您有
              <span class="date-num">{{expertDealtList[1].num || 0}}</span>
              节同步课即将开放
            </p>
            <!-- <p class="date-days">{{dayjs(item.createTime).format('YYYY-MM-DD')}}</p> -->
          </li>
        </ul>
        <!-- 其他身份待办事项 -->
        <ul class="dealtwith-list" v-else>
          <li class="dealtwith-item flex-align-between csp" v-for="(item,index) in dealtList" :key="index">
            <p class="date-name wes">{{item.textFront}}
              <span class="date-num">{{item.num || 0}}</span>
              {{item.textBack}}
            </p>
            <!-- <p class="date-days">{{dayjs(item.createTime).format('YYYY-MM-DD')}}</p> -->
          </li>
        </ul>
        <!-- <Empty :show="true"></Empty> -->
      </div>
      <div class="guide-box box-wrapper">
        <h4 class="guide-title">项目指导</h4>
        <ul class="guide-list flex-between">
          <li class="guide-item csp" v-for="(item,index) in guideList" :key="index" @click="toGuide(item)">
            <img :src="item.icon" alt="" class="data-icon">
            <p class="date-name" v-if="currentRole == 'expert'">{{item.name}}</p>
            <p class="date-name" v-else>{{item.name}}</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 课程表 -->
    <div class="schedule-wrapper">
      <h4 class="schedule-title"><span class="true-title">课程表</span></h4>
      <Schedule></Schedule>
    </div>
    <TipsBox v-if="welcomeTips" @go="toTrainplan" :text="joinProjectStr" @closebtn="welcomeTips = false"></TipsBox>
  </div>
</template>

<script>
  import {
    mapState
  } from "vuex";
  import TipsBox from "./component/tipsbox";
  export default {
    data() {
      return {
        welcomeTips: false, //项目通知弹窗显隐状态
        trainplanId: "",
        joinProjectStr: "",
        NoticeList: [],
        projectList: [], // 项目列表
        // activeName: null, // 当前项目名称
        toolsList: [ // 常用工具列表
          {
            name: '考勤登记',
            icon: require('@/assets/images/home/tools1.png'),
            href: '/attendance',
          },
          {
            name: '健康防疫',
            icon: require('@/assets/images/home/tools2.png'),
            href: '/health'
          },
          {
            name: '我要提问',
            icon: require('@/assets/images/home/tools3.png'),
            href: '/ask'
          }
        ],
        exportToolsList: [{
            name: '创建课程',
            icon: require('@/assets/images/home/tools1.png'),
            href: `/course/create/`,
          },
          {
            name: '创建作业',
            icon: require('@/assets/images/home/tools2.png'),
            href: '/createTheme_copy'
          },
          // {
          //   name: '创建教研',
          //   icon: require('@/assets/images/home/tools3.png'),
          //   href: ''
          // }
        ],
        dealtList: [ // 待办事项列表
          {
            textFront: '您有',
            textBack: '个作业待提交，请尽快提交',
            createTime: '2022-02-28',
            num: 3
          },
          {
            textFront: '您有',
            textBack: '个同步课程即将开课，请准时参加',
            createTime: '2022-02-28',
            num: 1
          },
          {
            textFront: '您有',
            textBack: '个异步课程学要学习，记得学习哦',
            createTime: '2022-02-28',
            num: 1
          },
          {
            textFront: '您今日',
            textBack: '还未提交！',
            createTime: '2022-02-28',
            num: '考勤'
          }
        ],
        expertDealtList: [ // 专家待办事项列表
          {
            textFront: '您有',
            textBack: '个作业',
            createTime: '2022-02-28',
            unCorrecting:null,
            unCorrectingAnswer:null
          }, {
            textFront: '您有',
            textBack: '节同步课程即将开放',
            createTime: '2022-02-28',
            num: 1
          },
        ],
        guideList: [{
            name: '培养计划',
            icon: require('@/assets/images/home/guide1.png'),
          },
          {
            name: '考核评价',
            icon: require('@/assets/images/home/guide2.png'),
          },
          {
            name: '实施方案',
            icon: require('@/assets/images/home/guide3.png'),
          },
          {
            name: '操作指南',
            icon: require('@/assets/images/home/guide4.png'),
          },
        ]
      };
    },
    components: {
      TipsBox
    },
    computed: {
      ...mapState({
        currentProject: state => state.user.currentProject,
        userInfo: state => state.user.userInfo,
        currentRole: state => state.user.role
      })
    },
    watch: {},
    methods: {
      // 去常用工具的具体页面
      toToolsPage(item) {
        if (!item.href) {
          this.$message('此功能暂未开放');
          return;
        }
        this.$router.push({
          path: item.href
        })
      },
      // 专家常用工具具体页面
      expertToToolsPage(item) {
        if (!item.href) {
          this.$message('此功能暂未开放');
          return;
        }
        if (item.name == '创建作业') {
          this.$router.push({
            path: `${item.href}`,
            query: {
              breadNumber: 4,
              projectId: this.currentProject.id,
              createId: this.userInfo.id,
            }
          })
          return;
        }
        this.$router.push({
          path: `${item.href}${this.currentProject.id}`,
          query: {
            breadNumber: 4
          }
        })
      },
      // 检查是否第一次登录，如第一次展示欢迎弹窗（项目通知）
      async checkFirstLogin() {
        let resData = await this.$Api.Course.check_first_login();
        if (resData.code === 200) {
          if (resData.data.isFirst && this.currentRole != "leader") {
            this.welcomeTips = true;
            resData.data.courses.forEach(element => {
              this.joinProjectStr += `“${element.name}”、`
            });
            this.joinProjectStr = this.joinProjectStr.substring(0, this.joinProjectStr.length - 1); // 去掉最后的顿号
            this.trainplanId = resData.data.courses[0].id;

          } else {
            this.welcomeTips = false;
          }
        }
      },
      toTrainplan() {
        this.$router.push(`/trainplan/${this.id}`)
      },

      //获取通知公告
      async getNewsListByProjectId() {
        let params = {
          projectId: this.currentProject.id,
          page: 1,
          limit: 4,
        }
        let resData = await this.$Api.Home.getNewsListByProjectId(params);
        // console.log(resData);
        if (!resData.data && JSON.stringify(resData.data.list) !== '[]') {
          this.NoticeList = [];
          return;
        }
        this.NoticeList = resData.data.list;
      },
      // 获取项目说明文件
      async getProjectFile() {
        let params = {
          projectId: this.currentProject.id,
        }
        let resData = await this.$Api.Project.getProjectFile(params);
        // console.log('getProjectFile', resData);
        if (JSON.stringify(resData.data) == '{}' || !resData) return;
        this.guideList.map(item => {
          item.info = resData.data[item.name]
        })
      },
      toGuide(item) {
        if (item.name == '培养计划') {
          this.$router.push(`/trainplan/${this.currentProject.id}`)
          return;
        }

        // 初项目概况外的其他项
        // 有数据
        if (JSON.stringify(item.info) !== '[]') {
          // console.log(item.info[0].identifier);
          this.$store.commit('getPdfUrl', item.info[0].identifier)
          this.$store.commit('getPdfName', item.name)
          let identif = item.info[0].identifier
          this.$router.push({
            path: `/pdf/${identif}`,
            query: {
              proid: this.currentProject.id
            }
          })
          return;
        }
        // 没数据 提示
        this.$message(`暂无${item.name}`)

      },
      // 获取代办事项
      getUnDo() {
        let data = {
          projectId: this.currentProject.id,
        }
        if (this.currentRole == 'expert') {
          this.$Api.Home.getExpertUndo(data)
            .then(res => {
              console.log('专家待办事项', res);
              this.$set(this.expertDealtList,0,{unCorrecting:res.data.unCorrecting,unCorrectingAnswer:res.data.unCorrectingAnswer});
              this.$set(this.expertDealtList,1,{...this.expertDealtList[1],num:res.data.liveTodo})
            })
            .catch(err => {
              console.log('获取专家代办事项失败', err)
            })
        } else {
          this.$Api.Home.getTeacherUndo(data)
            .then(res => {
              console.log('参训教师待办事项', res);
              this.$set(this.dealtList,0,{...this.dealtList[0],num:res.data.undoQuest});
              this.$set(this.dealtList,1,{...this.dealtList[1],num:res.data.liveTodo});
              this.$set(this.dealtList,2,{...this.dealtList[2],num:res.data.recordTodo});
            })
            .catch(err => {
              console.log('获取参训教师代办事项失败', err)
            })
        }
      }

    },
    mounted() {
      this.checkFirstLogin();
      this.getNewsListByProjectId()
      this.getProjectFile();
      this.getUnDo()
    },
    activated() {},
  };
</script>
<style lang="less" scoped>
  @import "./less/progress.less";
</style>