<template>
  <transition>
    <div class="tips-box">
      <div class="shadow" @click="closeTips"></div>
      <div class="tips-wrapper">
        <h3 class="tips-title">
          <span>项目通知</span>
          <i class="el-icon-close close" @click="closeTips"></i>
        </h3>
        <div class="tips-container">欢迎来到“双优云桥”参加<span class="project-name">{{text}}</span>
          项目，希望您在学习期间收获满满，您有任何问题都可以跟项目组联系。
        </div>
        <div class="btn-box">
          <button v-if="studentOrTeacher" class="btn csp" @click="go">查看培训计划</button>
          <button class="btn active csp" @click="closeTips">
            {{studentOrTeacher?'开始学习':'确定'}}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    props: {
      text: {
        default: "",
        type: String
      }
    },
    data() {
      return {
        studentOrTeacher: false
      };
    },
    methods: {
      closeTips() {
        this.$emit("closebtn");
      },
      go() {
        this.$emit("go");
      }
    },
    mounted() {
      let role = this.$store.state.user.role;
      if (role == "teacher" || role == "student") {
        this.studentOrTeacher = true;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .tips-box {
    .shadow {
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .4);
    }

    .tips-wrapper {
      position: fixed;
      z-index: 1000;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 500px;
      background-color: #fff;
      border-radius: 5px;

      .tips-title {
        position: relative;
        line-height: 49px;
        color: #fff;
        font-size: 20px;
        text-align: center;
        background-image: url("~@/assets/images/teach/create-title.png");
        background-size: cover;

        .close {
          cursor: pointer;
          position: absolute;
          right: 23px;
          top: 50%;
          transform: translateY(-40%);
        }

      }

      .tips-container {
        color: #666;
        padding: 55px 52px 0;
        font-size: 17px;

        .project-name {
          font-weight: bold;
        }
      }

      .btn-box {
        display: flex;
        justify-content: space-around;
        margin-top: 82px;
        padding: 0 50px 32px;

        .btn {
          width: 150px;
          border: 1px solid #508EF9;
          border-radius: 5px;
          line-height: 36px;
          font-size: 14px;
          font-weight: 500;
          color: #508EF9;
          background-color: #fff;
          border: 1px solid #508EF9;
        }

        .active {
          color: #fff;
          background-color: #508EF9;
        }
      }


    }

    @media screen and (min-width:1250px) {
      .tips-wrapper {
        width: 600px;
      }
    }
  }
</style>